/* 

kumbang_UI_01 메인
kumbang_UI_02 살래요/팔래요
kumbang_UI_03 간평감정 서비스
kumbang_UI_04 매수/매도
kumbang_UI_05 스플래시
kumbang_UI_06 튜토리얼
kumbang_UI_07 코칭 

*/
/*공통*/

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;
    src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}

body, h1, h2, h3, h4, h5, h6, input, textarea, select { font-family: 'Noto Sans KR', sans-serif; }

ol,li,ul{list-style: none; padding: 0; margin: 0;}
header{display: flex; justify-content: center; align-items: center;}
header img{width: 161px; padding: 15px 0;}
.up{color: #D51F1F;}
.down{color: #4B89FF;}
footer{background-color: #F7F7F8;font-family: 'Noto Sans KR';}

.spoqa{font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;}
.kumbang_wrap span {font-family: 'Noto Sans KR';}
.buy_wrap span{font-family: 'Noto Sans KR' ;}
.sell_wrap span{font-family: 'Noto Sans KR' ;}
.service_wrap span{font-family: 'Noto Sans KR';}
/* ==============================
    kumbang_UI_01 메인
============================== */


/*메인슬라이드*/
.visual_slide img{width: 100%;}
.visual_slide {position: relative;}
.visual_slide ul{display: flex;position: absolute;bottom: 60px; left: 30px;}
.Pagination{width: 6px; height: 6px; border-radius: 50%; background-color: #D2D2D2;margin-right: 7px;}
.Pagination.on{background-color: #8E8E8E;}

.kumbang_wrap{position: relative; padding:0px 13px ;margin-top: -30px; border-radius:22px 22px 0 0 ; background-color: #fff; box-shadow: 0 0 10px rgba(0,0,0, 0.16);font-family: 'Noto Sans KR'}
.kumbang_main{padding-bottom: 22px;}

/*본인인증*/
.kumbang_main .main_pop{position:relative; margin-top: 13px;border:2px solid #F06809; border-radius: 5px; display: flex; justify-content: center; align-items: center;flex-direction: column;padding: 13px 0;}
.kumbang_main .main_pop .pop_noti{color: #555555; font-size: 13px;margin-bottom: 6px;font-weight: 500;line-height: 18px;}
.kumbang_main .main_pop .pop_noti img{height: 18px; vertical-align: top;}
.kumbang_main .main_pop .pop_noti .btn_close{position: absolute;top: 13px; right: 16px;}
.kumbang_main .main_pop button{background-color: #F06809; border:none; border-radius: 50px;line-height: 13px; color: #fff; font-weight: 700;font-size: 13px;padding: 6px 13px 7px;box-shadow: 0 3px 6px rgba(0,0,0,0.16);}
.kumbang_main .main_pop button img{height:12px;margin-left: 3px; vertical-align: top;line-height: 13px;}


.kumbang_main .time{font-size: 10px; color: #A3A3A3;float: right;padding-bottom: 5px;margin-top: 5px;}

/*아코디언*/
.kumbang_main ul li .li_money{display: flex; align-items: center;width: 58%;justify-content:flex-end;}
.kumbang_main ul li .li_price{font-weight: 700;font-size: 15px;margin-right: 13px;text-align: right;line-height: 17px;}
.kumbang_main ul li .li_price .spoqa{color: #111;}
.kumbang_main ul li .li_price .bot_price{font-weight: 400; color:#666666;font-size: 10px;}
.kumbang_main ul li .li_tit{font-weight: 700; font-size: 17px;width: 30%;color: #111;letter-spacing: -0.38px;}
.kumbang_main ul li .li_tit.other{width: 90%;}
.kumbang_main ul li .li_change{font-size: 10px;padding: 2px 0px;border-radius: 5px;font-weight: 700;text-align: center;width: 60px;}
.kumbang_main ul li .li_change img{height: 6px;}
.kumbang_main ul li .li_change.normal{background-color: #f4f4f4;}
.kumbang_main ul li .li_change.up{background-color: #FCF2F2;}
.kumbang_main ul li .li_change.down{background-color:#ECF3FB ;}

.accordion {width:100%;}
.accordion [type=radio], .accordion [type=checkbox] { display:none;}
.accordion label {width:100%; height: 54px;display:block;border-radius:5px; color:#333; -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;display: flex; justify-content: space-between; align-items: center;}
.accordion ul li label::after{content: "";  display: block; width: 48px; height: 48px;background-repeat: no-repeat;}
.accordion ul li label:hover, .accordion [type=radio]:checked ~ label, .accordion [type=checkbox]:checked ~ label {color:#333;}
.accordion .content { 
    overflow:hidden;border:none; 
    -webkit-transition: all .3s ease-out;border-color: #f0f0f0;
    -moz-transition: all .3s ease-out;
}
.accordion{animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
}

.vertical >ul >li {width: 100%;overflow:hidden;position:relative;margin:0 0 16px;border-radius:5px;background-color: transparent; box-shadow: 1.7px 2.9px 10px rgba(0,0,0, 0.16);  -webkit-box-sizing: border-box; box-sizing: border-box; }
.vertical ul li label { padding: 0 0 0 17px;}
.vertical ul li .content { height:0px; border-top:0;}
.vertical ul li .content .content-price{padding: 0px 17px;}
.vertical [type=radio]:checked ~ label ~ .content, .vertical [type=checkbox]:checked ~ label ~ .content {border-top:0;border-radius:0 0 5px 5px;overflow-y: scroll;height:520px;}
.vertical [type=radio]:checked ~ label ~ .content, .vertical [type=checkbox]:checked ~ label ~ .content.content3{ height: 240px;}
.accordion .content .price-graph{height: 200px; border: 1px solid;margin-top:10px ;}
.accordion .content .price_btn{display: flex; justify-content: space-between;margin: 10px 0 20px;}
.accordion .content .price_btn button{width: 48%; border-radius: 20px;background-color: #fff;border: 2px solid;font-size: 18px;padding: 7px 0 6px;font-weight: 500;display: flex;justify-content: center; align-items: center;}
.accordion .content .price_btn button img{height: 25px;vertical-align: top;margin-right: 3px;}
.accordion .content .price_btn button.price_buy{border-color:#E32626 ; color: #E32626;}
.accordion .content .price_btn button.price_sell{border-color:#2C69A1 ; color: #2C69A1;}
.accordion .content .price_product{padding-left: 17px;}
.accordion .content .price_product .btn_all{margin-right: 17px;}
.accordion .content .price_product .product_tit{display: flex; justify-content: space-between; align-items: center;line-height: 13px;}
.accordion .content .price_product .product_tit img{height: 13px;vertical-align: top;}
.accordion .content .price_product .product_tit h3{font-size: 15px;margin: 0;letter-spacing: -0.38px;}
.accordion .content .price_product .product_cont ul{white-space: nowrap;overflow-y: hidden; overflow-x: scroll;padding-top: 14px;padding-bottom: 0;}
.accordion .content .price_product .product_cont ul li.product_card{overflow: hidden; margin-bottom: 0; height: 180px;width: 125px; margin-right: 10px;border-radius: 5px;padding:10px 7px;display: inline-block; box-shadow: 1.7px 2.9px 10px rgba(0,0,0, 0.16); }
.accordion .content .price_product .product_cont ul li.product_card p{margin: 0; font-size: 11px;white-space: normal;letter-spacing: 0.5px;}
.accordion .content .price_product .product_cont ul li.product_card p.product_price{font-size: 12px;position: relative;}
.accordion .content .price_product .product_cont ul li.product_card p.product_price span{font-size: 15px; font-weight: 700;}
.accordion .content .price_product .product_cont ul li.product_card p.product_price span.org_per{position: absolute;right: 0; bottom: 0;color: #FF842B; font-size: 13px; font-weight: 700;}
.accordion .content .price_product .product_cont ul li.product_card h3{margin: 0;}
.accordion .content .price_product .product_cont ul li.product_card img{height: 105px;}
.accordion .content .price_product .product_cont ul li.product_card.gold{background-color: #F8F1E9; border:1px solid #EADBCA;text-align: center;}
.accordion .content .price_product .product_cont ul li.product_card.gold h3{font-size: 15px;margin-bottom: 3px;}
.accordion .content .price_product .product_cont ul li.product_card.gold p{font-size: 11px; letter-spacing: -0.25px; color:#FF842B ; text-decoration: underline;}
.accordion .content .price_product .product_cont ul li.product_card.gold img{height: 84px;margin-top: 15px;}
.accordion .content .price_product .product_cont ul li.product_card.silver{background-color: #EDEDED;border:1px solid #D9D9D9;text-align: center;}
.accordion .content .price_product .product_cont ul li.product_card.silver h3{font-size: 15px;margin-bottom: 3px;}
.accordion .content .price_product .product_cont ul li.product_card.silver p{font-size: 11px; letter-spacing: -0.25px; color:#FF842B ; text-decoration: underline;}
.accordion .content .price_product .product_cont ul li.product_card.silver img{height: 84px;margin-top: 15px;}
.accordion .content .price_product .product_cont ul li.product_card.jewelry{background:url(../images/jewelry.png); background-size: contain;   border:1px solid #D9D9D9;text-align: center;}
.accordion .content .price_product .product_cont ul li.product_card.jewelry h3{font-size: 15px;margin-bottom: 3px;padding-top: 10px;}
.accordion .content .price_product .product_cont ul li.product_card.jewelry p{font-size: 11px; letter-spacing: -0.25px; color:#FF842B ; text-decoration: underline;}
.accordion .content .price_product .product_cont ul li.product_card.jewelry img{height: 84px;margin-top: 15px;}
.accordion .content3 .pop_ing{border:2px solid #F06809; padding: 15px 20px;border-radius: 5px;}
.accordion .content3 .pop_ing .ing_tit{font-size: 15px; font-weight: 700; color: #F06809;margin-bottom: 8px;}
.accordion .content3 .pop_ing .ing_cont p{padding-left: 6px; width: auto; box-shadow: none;font-size: 13px; letter-spacing: -0.67px; color: #555; font-weight: 500; text-indent: -7px;margin: 0px 3px 6px 0; }
.accordion .content3 .pop_ing .ing_cont p:last-child{padding-bottom: 7px;}
.accordion .content3 .pop_ing .ing_cont p::before{content: "• ";display: block;color: #555;float: left;margin-right: 7px;}
.accordion .content3 .pop_ing .ing_btn{background-color: #F06809;color: #fff; text-align: center;border-radius: 20px;width: 80%;height: 26px; margin: 0 auto;font-weight: 700; font-size: 13px;line-height: 26px;}
.accordion .content3 .pop_ing .ing_btn img{height: 18px; vertical-align: text-bottom;}
.vertical [type=radio]:checked ~ label ~ .content .btn_cx_up,
.vertical [type=checkbox]:checked ~ label ~ .content .btn_cx_up {position:absolute;top:3px;right:0px;width:48px;height:48px;background: #fff url(../images/up2.png) no-repeat 0 0;
    background-size: contain;z-index:2;}
.vertical  .btn_cx_down {position:absolute;top:3px;right:0px;width:48px;height:48px;background: #fff url(../images/down2.png) no-repeat 0 0;
     background-size: contain;z-index:1;}
/*공지*/
.kumbang_noti{display: flex; justify-content: space-between;align-items: center;padding-top: 6px;}
.kumbang_noti p{ width: 80%;overflow: hidden;white-space: nowrap;text-overflow:ellipsis; font-size: 13px; font-weight: 500;color: #555555;margin: 0 0 0 9px;letter-spacing: -0.67px;}
.kumbang_noti img{height: 13px; margin-right: 3px;vertical-align: middle;}
button.btn_all{background-color: #fff; border: 1px solid #ccc; color: #777; font-size: 11px; border-radius: 20px; padding: 4px 10px 3px;}


/*거래절차,감정평가,이용가이드 박스*/
.kumbang_guide{background-color: #EFEFF0;padding:20px 13px ;font-family: 'Noto Sans KR'}
.kumbang_guide .notice{display: flex; justify-content: space-between;}
.kumbang_guide .notice .tr_notice{background-color:rgba(72,66,66,0.8) ;  width: 48%; border-radius: 5px;box-shadow: 1.7px 2.9px 10px rgba(0,0,0, 0.25);padding: 14px 17px 4px;}
.kumbang_guide .notice .ev_notice{background-color:#FF842B ; width: 48%; border-radius: 5px;box-shadow: 1.7px 2.9px 10px rgba(0,0,0, 0.25);padding: 14px 17px 4px;}
.kumbang_guide .notice .noti_tit{font-weight: 700; color: #fff;font-size: 16px; letter-spacing: -0.38px;}
.kumbang_guide .notice .noti_cont{display: flex; justify-content: space-between; letter-spacing: -0.38px;}
.kumbang_guide .notice .tr_notice img{height: 50px;}
.kumbang_guide .notice .ev_notice img{height: 61px;}
.kumbang_guide .notice .noti_cont p{font-weight: 400; color: #fff; font-size: 12px;line-height: 15px; margin-top: 6px; margin-bottom: 0;}
.kumbang_guide .guide{background-color: #fcfcfc; border-radius: 5px; border: 1px solid #DEDEDE;padding: 8px 25px 10px;margin-top: 15px;display: flex; justify-content: space-between;align-items: center;}
.kumbang_guide .guide .noti_tit{font-weight: 700;font-size: 16px;}
.kumbang_guide .guide .noti_cont{font-weight: 400; font-size: 12px;line-height: 16px; margin-top: 1px; margin-bottom: 0;}
.kumbang_guide .guide img{height: 70px;}

/*대리점 안내,연결*/
.kumbang_agency{border-bottom: 1px solid #E2E2E2;border-top: 1px solid #E2E2E2;display: flex;padding: 14px 0;font-size: 15px;color: #333;font-weight: 500;line-height: 29px;}
.kumbang_agency img{height: 29px;vertical-align: top;}
.kumbang_agency .agency_noti{width: 50%;text-align: center;}
.kumbang_agency .agency_conc{width: 50%;text-align: center}
.kumbang_agency .agency_noti::after{content: ""; background-color: #e2e2e2; display: flex;align-items: center; width: 2px; height: 100%;float:right;}

/*푸터*/
.bottom_logo{display: flex;flex-direction: column; align-items: center; justify-content: center;padding: 13px 0 79px; font-size: 10px; letter-spacing: -0.25px; color: #333;}
.bottom_logo img{height: 21px;margin-bottom: 5px;}
.bottom_logo p{margin: 0;}



/* ==============================
    kumbang_UI_02 살래요/팔래요
============================== */
input[type="radio"] {display:none;} 
input[type="radio"] + label {display:inline-block;height: 40px; color:#666;font-size:16px;text-align: center;line-height: 40px; width: 50%; float: left;font-family: 'Noto Sans KR'; background-color: #fff;} 
input[id="tab02"]:checked + label {color:#2C69A1;border-bottom: 3px solid #2C69A1; font-weight: 700;}
input[id="tab01"]:checked + label {color:#E32626;border-bottom: 3px solid #E32626; font-weight: 700;}

.conbox {width:100%;margin:0 auto;display:none;font-family: 'Noto Sans KR';padding-bottom: 66px;} 
input[id="tab01"]:checked ~ .con1 {display:block;} 
input[id="tab02"]:checked ~ .con2 {display:block;}


.buy_wrap{margin-top: 40px;font-family: 'Noto Sans KR'}
.buy_list li.bar, .sell_list li.bar{content: ""; display:block; width: 40px; height: 1px; background-color: #fff; opacity: 0.4;float: right;margin-top: 45px;}

.buy_wrap h3 ,.buy_wrap p{margin: 0;}
.buy_main{background-color: #FC6806; color: #fff;padding:45px 30px 55px;}
.buy_main .buy_tit{font-size: 20px; text-decoration: overline; text-decoration-style: solid; font-weight: 700;margin-bottom: 11px;}
.buy_main .buy_cont{font-size: 15px;letter-spacing: -1.5px;}
.buy_list{margin: 30px auto 0;display: flex; justify-content:center;}
.buy_list li{text-align: center;width: 70px; }
.buy_list li span{background-color: #fff; color: #FC6806;font-weight: 700;font-size: 13px;padding: 0 11px; border-radius: 10px;}
.buy_list li img{height: 52px;margin: 5px 0;}
.buy_list li p{line-height: 16px; font-size: 13px; font-weight: 500;margin-top: 5px;}

.buy_order{ text-align: center; box-shadow: 0 -3px 6px rgba(0,0,0, 0.10);border-radius: 15px 15px 0 0; margin-top: -30px;background-color: #fff;box-sizing: border-box;}
.buy_order div {
    padding: 0px 30px;
}
.buy_order .order_tit{padding-top: 50px; text-decoration: overline;text-decoration-thickness: 2px;font-size: 19px;}
.buy_order .order_cont{font-size: 15px;margin-top: 9px;margin-bottom: 25px;font-weight: 500;}
.buy_order .order_cont span{color: #E32626;}
.buy_order .order_txt{font-size: 13px;margin-top: 20px;margin-bottom: 10px;color: #666666; line-height: 18px; letter-spacing: -0.33px;}
.buy_order .guide_view{font-size: 12px; text-decoration-line:underline;
    text-underline-position: under;color: #FF6E0E;margin-bottom: 30px; }
.buy_order .buy_list_img{width: 100% ;}


.bg_gr{background-color: #F3F3F3;}
.bg_gr .order_tit{padding-top: 62px !important;}
.bg_gr .org_bt{margin-bottom: 62px;}
.org_bt{box-sizing: border-box; margin-bottom: 50px; border: none; background-color:#FF6E0E ;width: 100%; border-radius: 20px;height: 42px; line-height: 42px;
    color: #fff; font-size: 14px; font-weight: 700;position: relative;box-shadow: 0 3px 6px rgba(0,0,0, 0.16);}
.ic_right{height: 15px;width: 8px; position: absolute; right: 23px;top:33%;}

.sell_wrap{margin-top: 40px;font-family: 'Noto Sans KR'}
.sell_wrap h3 ,.sell_wrap p{margin: 0;}
.sell_main{background-color: #333333; color: #fff;padding:45px 30px 55px;}
.sell_main .sell_tit{font-size: 20px; text-decoration: overline; text-decoration-style: solid; font-weight: 700;margin-bottom: 11px;}
.sell_main .sell_cont{font-size: 15px;letter-spacing: -1.5px;}
.sell_list{margin: 30px auto 0;display: flex; justify-content:center;}
.sell_list li{text-align: center; width: 70px;}
.sell_list li span{background-color: #fff; color: #333;font-weight: 700;font-size: 13px;padding: 0 11px; border-radius: 10px;}
.sell_list li img{height: 50px;margin: 5px 0;}
.sell_list li p{line-height: 16px; font-size: 13px; font-weight: 500;margin-top: 5px;}


.sell_order{ text-align: center; box-shadow: 0 -3px 6px rgba(0,0,0, 0.10);border-radius: 15px 15px 0 0; margin-top: -30px;background-color: #fff;box-sizing: border-box;}
.sell_order div {
    padding: 0px 30px;
}
.sell_order .order_tit{padding-top: 50px; text-decoration: overline;text-decoration-thickness: 2px;font-size: 18px;}
.sell_order .order_cont{font-size: 15px;margin-top: 9px;margin-bottom: 25px;font-weight: 500;}
.sell_order .order_cont span{color: #2C69A1;}
.sell_order .order_txt{font-size: 13px;margin-top: 20px;margin-bottom: 10px;color: #666666; line-height: 18px; letter-spacing: -0.33px;}
.sell_order .guide_view{font-size: 12px; text-decoration-line:underline;
    text-underline-position: under;color: #FF6E0E;margin-bottom: 30px; }
.sell_order .sell_list_img{width: 100%;}

/* ==============================
    kumbang_UI_03 간평감정 서비스
============================== */


.service_wrap{font-family: 'Noto Sans KR'}
.service_list li.bar{content: ""; display:block; width: 40px; height: 1px; background-color: #fff; opacity: 0.4;float: right;margin-top: 45px;}

.service_wrap h3 ,.ser vice_wrap p{margin: 0;}
.service_main{background-color: rgba(255, 248,243, 0.5); color: #333;padding:28px 30px 40px;}
.service_main .service_tit{font-size: 20px; text-decoration: overline; text-decoration-style: solid; font-weight: 700;margin-bottom: 11px;}
.service_main .service_cont{font-size: 15px;letter-spacing: -0.5px;}
.service_list{margin:0px auto;display: flex; }
.service_list ul{display: flex;width: 100%;align-items:center; height: 161px;}
.service_list ul .service_img {width: 33%;height: 161px;}
.service_list ul .service_img img{height: 100%;}
.service_list li{text-align: center; font-size: 13px; margin-right: 5%;width: 16%;}
.service_list li img{height: 50px;margin-bottom: 7px;}


.service_order{ box-shadow: 0 -3px 6px rgba(0,0,0, 0.10);padding: 15px 30px 66px;border-radius: 15px 15px 0 0; margin-top: -40px;background-color: #fff;box-sizing: border-box;}
.service_order > div::after{content: "";display: block; border-bottom: 1px dashed #ddd;margin-top: 25px;}
.service_order div:nth-child(3):after{display: none;}
.service_order .order_tit{padding-top: 25px; font-size: 15px;}
.service_order .order_cont{font-size: 13px;margin-top: 9px;margin-bottom: 20px;font-weight: 500;letter-spacing: -0.33px;}
.service_order .order_txt{margin-top: 15px;color: #666666; line-height: 18px; letter-spacing: -0.33px;background-color: #F8F8F8; border: 1px solid #CCCCCC; border-radius: 3px; box-sizing: border-box;padding: 5px 7px 5px 7px;}
.service_order .order_txt p{text-indent: -15px; margin-left: 15px;letter-spacing: -0.3px; font-size: 12px;}
.service_order .order_txt p::before{content: "※ ";}
.service_order .order_txt span{font-weight: 700;}
.service_order button img{vertical-align: middle;}
.service_order .org_bt{margin:0 auto;box-sizing: border-box;border: none; background-color:#111 ;width: 100% ; border-radius: 20px;
    color: #fff; font-size: 14px; font-weight: 700;position: relative;box-shadow: 0px 3px 6px rgba(0,0,0, 0.16); height: 42px;}
.service_order .wh_bt{margin:0 auto;box-sizing: border-box;border: none; background-color:#fff; width: 100% ; border-radius: 20px;
        color: #111 ;border:1px solid #111 ; font-size: 14px; font-weight: 700;position: relative; height: 42px;}
.service_order .service_list_img{width: calc(100% - 60px);}
.service_order > p{font-size: 10px; color: #666666; text-align: center;margin: 25px auto 20px;line-height: 13px;}

/* ==============================
    kumbang_UI_04 매수/매도
============================== */


/* ==============================
    kumbang_UI_05 스플래시
============================== */


/* ==============================
    kumbang_UI_06 튜토리얼
============================== */
.tuto_wrap .org_bt{margin:  0 auto; position: fixed;width:calc(100% - 50px); bottom: 28px; box-sizing: border-box; font-family: 'Noto Sans KR'}
.tuto{padding: 0 25px; height: 100% ; height: 100vh;}
.tuto1{background: url(../images/tuto1.png) bottom center ;background-size: contain; background-repeat: no-repeat;}
.tuto2{background: url(../images/tuto2.png) bottom center ;background-size: contain; background-repeat: no-repeat;}
.tuto3{background: url(../images/tuto3.png) bottom center ;background-size: contain; background-repeat: no-repeat;}
/* ==============================
    kumbang_UI_07 코칭
============================== */
.coaching_wrap{background-color: rgba(0,0,0, 0.8); width: 100%; height: 100vh; font-family: 'Noto Sans KR'}
.coaching{position: relative; background: url(../images/coaching.png) top center;height: 0;padding-bottom: 200%;  width: 100%;background-size: contain; background-repeat: no-repeat;}
.coaching .none_btn{position: absolute; top: 24px; left: 13px;padding: 8px 26px; border: 1px solid #fff; color: #fff; font-size: 15px; font-weight: 500;}
.coaching .close_btn{position: absolute; top: 26px; right: 23px; height: 28px; width: 28px;}
.coaching .close_btn img{ height: 28px; width: 28px;}


/* ==============================
    280px 이하
============================== */



@media all and (max-width:280px) {
    .kumbang_main ul li .li_tit{font-size: 15px;}
    .kumbang_main ul li .li_price{font-size: 12px;}
    .kumbang_main ul li .li_price .bot_price .spoqa{font-size: 8px;}
    .kumbang_main ul li .li_price .bot_price {font-size: 8px;}
    .kumbang_main ul li .li_money{width: 60%;}
    .kumbang_main ul li .li_change{font-size: 8px;padding: 2px 5px;width: 55px;}
    .accordion .content .price_btn button{font-size: 16px;}
    .accordion .content .price_product .product_tit h3{font-size: 13px;}
    .accordion .content .price_product .btn_all{font-size: 9px;}
    .accordion .content .price_product .product_cont ul li.product_card.gold h3{font-size: 13px;}
    .accordion .content .price_product .product_cont ul li.product_card.silver h3{font-size: 13px;}
    .accordion .content .price_product .product_cont ul li.product_card p.product_price span{font-size: 13px;}
    .accordion ul li label::after{width: 13px ; height: 6px; background-size: 13px 6px;}
    .accordion .content3 .pop_ing .ing_tit{font-size: 13px;}
    .accordion .content3 .pop_ing .ing_cont p{font-size: 11px;letter-spacing: -1px;}
    .accordion .content3 .pop_ing .ing_btn{font-size: 11px;}
    button.btn_all{font-size: 9px;padding: 4px 5px 3px;}
    .kumbang_guide .guide{padding: 8px 23px 10px;}
    .kumbang_guide .notice .noti_tit{font-size: 14px;}
    .kumbang_guide .notice .noti_cont{justify-content: space-around;}
    .kumbang_guide .notice img{height: 48px;}
    .kumbang_guide .notice .ev_notice{padding: 10px;}
    .kumbang_guide .notice .tr_notice{padding: 10px;}
    .kumbang_agency{font-size: 13px;}
    .service_list ul .service_img img{height: auto; width: 100%;}
}
@media(min-width:280px) and (max-width:320px) {
    .accordion .content3 .pop_ing .ing_cont p{font-size: 11px;}
}
/* ==============================
    kbtrade_UI_01 상품등록
============================== */
    .trade_wrap{background-color: #FFFCF9; color: #333;padding:30px 16px 88px;font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important;
        background-size: cover;border-top: 1px solid #DDDDDD;}
        .trade_wrap .bg01 {position:fixed; top:0; left:0; width:100%; height:100%; background:#FFFCF9;z-index:-1;}
        
        
.trade_wrap span{font-family: 'Noto Sans KR' ;}
.trade_wrap p,.trade_wrap h3 {margin: 0;}

.ok_btn {z-index: 9999; width: 100%; height: 48px; text-align: center; line-height: 48px;font-weight: 700; color: #98999A; background-color: #DDDDDD; position: fixed; bottom: 0;left: 0;}
.ok_btn.on{background-color: #FF6E0E; color: #fff;}

p.org{color:#FF6E0E !important;}
.pd_fst .pd_txt{ font-size: 16px; font-weight: 500; padding-left: 21.5px;padding-bottom: 4.5px;color: #777777;}
.pd_fst .pd_tit{font-size: 20px; font-weight: 500; padding-left: 21px;color: #222222;}
.pd_txt{ font-size: 16px; font-weight: 500;color: #777777;}
.pd_tit{font-size: 20px; font-weight: 500;  padding-bottom: 4.5px;color: #222;}
.pd_fst_checkbox{padding: 28px 24px 0;}
.pd_fst_checkbox .pd_box{border: 1px solid #F9F9F9; background-color: #fff; padding: 11px 28px 11px 12px;margin-bottom: 8px}
.pd_fst_checkbox .pd_box.on{border-color: #FF6E0E}
.pd_fst_checkbox .pd_box.on .ck_cir{background-color:#FF6E0E; }
.pd_fst_checkbox .pd_box .ck_cir{width: 22px; height: 22px; float: left;margin-right: 9px;background-color:#DBDBDB; display: flex; justify-content: center; align-items: center; border-radius: 11px;}
.pd_fst_checkbox .pd_box .ck_cir.on{background-color:#111111; }
.pd_fst_checkbox .pd_box .ck_cir img{width: 12px; height: 10px; }
.pd_fst_checkbox .pd_box p{margin-left: 30px; font-size: 14px; letter-spacing: -0.25px; line-height: 22px;}
.pd_fst_checkbox .pd_box .ex_img{display: flex; justify-content: flex-end;margin-top: 14px;}
.pd_fst_checkbox .pd_box .ex_img img{width: calc(100% - 31px);}

/* ==============================
    kbtrade_UI_02 제품정보
============================== */

.progressbar{width: 100%; height: 4px; background-color:#E0E0E0 ;position: absolute;}
.per33{width: 33.33%;position: absolute;top: 0; left: 0; height: 4px; background-color: #FF6E0E;}
.per66{width: 66.66%;position: absolute;top: 0; left: 0; height: 4px; background-color: #FF6E0E;}
.per100{width: 100%;position: absolute;top: 0; left: 0; height: 4px; background-color: #FF6E0E;}
.pd_sec .pd_txt{ padding-left: 11px;padding-bottom: 0;}
.pd_sec .pd_tit{ padding-left: 11px;padding-bottom: 4.5px;}
.pd_sec_checkbox{padding:20px 0 0 0;}
span.org{color:#FF6E0E ; font-size: 10px; font-weight: 500;}
.pd_sec_checkbox .pd_box {margin-bottom: 15px;}
.pd_sec_checkbox .pd_box:last-child{margin-bottom: 0;}
.pd_sec_checkbox .pd_box .pd_box_tit{font-size: 14px;color: #333; font-weight: 500;margin-left: 6px;}
.pd_sec_checkbox .pd_select_box {width: 100%;height: 50px; border: 1px solid #EAEAEA; background-color: #fff; padding: 11px 12px;margin-top: 6px; font-size: 16px; color: #98999A; font-weight: 500;position: relative;}
.pd_sec_checkbox .pd_select_box:hover{border-color: #656667;}
.pd_sec_checkbox .pd_select_box::after{content: ""; display: block; background: url(../images/ic_con_down.png); width: 8px; height: 6px; background-size: contain; position: absolute;right: 21px;top: 50%;}
.pd_sec_checkbox .pd_select_box.none{display: flex; justify-content: space-between; align-items: center;}
.pd_sec_checkbox .pd_select_box.none::after{display: none;}
.pd_sec_checkbox .pd_select_box.cal::after{content: ""; display: block; background: url(../images/ic_cal.png); width: 23px; height: 23px; background-size: contain; position: absolute;right: 21px;top: 30%;}
.pd_sec_checkbox .pd_select_box.won::after{content: "원"; display: block; background: none;color: #333; font-weight: 500; top: 25%;}
.pd_sec_checkbox .pd_box ul {margin-top: 5px; display: flex; justify-content: space-between; align-items: center;}
.pd_sec_checkbox .pd_box ul li{border: 1px solid #DBDBDB; width:72px;height: 72px;line-height: 72px; border-radius: 50%;text-align: center;font-size: 12px; font-weight: 500; background-color: #fff;}
.pd_sec_checkbox .pd_box ul li.on{background-color: #111; color: #fff; border-color: #111;}
/*에러*/
.clear_bt{width: 15px; height: 15px; border-radius: 50%; background-color: #EFF0F1; display: flex; justify-content: center; align-items: center;}
.clear_bt img{width: 6px; height: 6px; vertical-align: top;}
.pd_sec_checkbox .pd_box.error .pd_box_tit{color: #F80003;}
.pd_sec_checkbox .pd_box.error .pd_box_tit span.org{color: #F80003;}
.pd_sec_checkbox .pd_box.error .pd_select_box {border: 1px solid #F80003; background-color: #fff; padding: 11px 12px;margin-top: 6px; font-size: 16px; color: #333; font-weight: 500;position: relative;}
.pd_sec_checkbox .pd_box.error .error_txt{color:#F80003; font-size: 10px;font-weight: 300;margin-left: 6px;margin-top: 4px;}
/*공백*/
.pd_sec_checkbox .pd_box.disabled .pd_select_box{background-color: #EAEAEA;border-color: #dbdbdb;}
/*성공*/
.pd_sec_checkbox .pd_box.success .pd_select_box {border: 1px solid #43B985; color:#333 ;}
.pd_sec_checkbox .pd_box.success .pd_select_box.none::after{ display: block; background: url(../images/ic_check_gr.png);width: 12px; height: 9px; background-size: contain; position: absolute;right: 21px;top: 45%;}
/*타입*/
.pd_sec_checkbox .pd_box.type .pd_select_box {border: 1px solid #FF6E0E; color:#333 ;}
/*포커즈*/
.pd_sec_checkbox .pd_box.focus .pd_select_box {border: 1px solid #FFD880; color:#333 ;}

/*input text*/
.pd_box .pd_box_text{position: relative;width: 100%; border: 1px solid #EAEAEA; background-color: #fff; padding: 11px 12px;margin-top: 6px; }
.pd_box input{width: 90%;font-size: 16px; color: #98999A; font-weight: 500;border: none;}
.clear{width: 15px; height: 15px; border-radius: 50%; background-color: #EFF0F1;display: flex; justify-content: center; align-items: center;position: absolute; top: 40%; right: 21px;}
.clear img{width: 6px; height: 6px; vertical-align: top;}
.pd_box.success .pd_box_text {border: 1px solid #43B985; color:#333 ;}



/* ==============================
    kbtrade_UI_03,07 선택창
============================== */
.trade_wrap_bk{background-color: rgba(0,0,0, 0.67); width: 100%; height: 100%; color: #333;}
.trade_wrap_bk .pd_select{width:100%;position: fixed; bottom: 0;background-color: #fff;border-radius: 16px 16px 0 0; overflow: hidden;}
.trade_wrap_bk .pd_select .select_tit{position: relative; background-color: #F5F5F5;}
.trade_wrap_bk .pd_select .select_tit h3{font-size: 16px; padding: 18px 0 ; margin: 0;text-align: center;}
.trade_wrap_bk .pd_select .select_tit img{width: 18px; height: 18px; position: absolute;top: 22px; right: 19px;}
.trade_wrap_bk .pd_select ul{background-color: #fff;display: flex; flex-flow: wrap row; padding: 16px 30px;}
.trade_wrap_bk .pd_select ul li{ padding: 10px 0; width: 50%; box-sizing: border-box;color: #333333;}
.trade_wrap_bk .pd_select ul li.on {font-weight: 700;color: #333;}
.trade_wrap_bk .pd_select ul li.off {font-weight: 400;color: #999;}
.trade_wrap_bk .pd_select ul li.on .ck_cir{background-color: #FF6E0E;}
.trade_wrap_bk .pd_select ul li .ck_cir{width: 20px; height: 20px; float: left; border-radius: 50%; background-color:#DBDBDB ; display: flex; justify-content: center; align-items: center; margin-right: 8px;}
.trade_wrap_bk .pd_select ul li .ck_cir img{width: 10px;}
.trade_wrap_bk .pd_select .ok_btn{position: relative;}
/* ==============================           
    kbtrade_UI_04 제품 사진등록
============================== */
.pd_thd .pd_pic {margin: 32px 0;}
.pd_thd .pd_pic ul{display: flex; align-items: center;}
.pd_thd .pd_pic ul li{margin-right: 8px; border: 1px solid #DBDBDB; background-color: #fff; width: 76px; height: 76px;display: flex;justify-content: center; align-items: center;position: relative;}
.pd_thd .pd_pic ul li:last-child{margin-right: 0;}
.pic_product{width: 100%;height: 100%;object-fit: cover;}
.pic_ic{width:22px; height: 20px;}
.pic_plus{width:14px; height: 14px;}
.close_bt{width: 20px; height: 20px; position: absolute;top: 0; right: 0;}

.pd_thd .pd_pic_guide{padding:16px 12px ; background-color: #FAF6F0;}
.pd_thd .pd_pic_guide .pic_guide_tit {font-size: 14px; font-weight: 500;margin-bottom: 6px;}
.pd_thd .pd_pic_guide .pic_guide_cont li{font-size: 12px; font-weight: 300;line-height: 20px;}

.pd_pic_ex{margin-top: 25px;}
.pd_pic_ex img{width: 100%;}
/* ==============================
    kbtrade_UI_05 제품 보증서 및 구성품등록
============================== */
.qs1{font-size: 16px;font-weight: 500;margin-top: 20px;margin-bottom: 16px;color: #444;}
.pd_qs1 ul{display: flex; justify-content: space-between;}
.pd_qs1 ul li{width: 48%; box-sizing: border-box;border-radius:22px; border: 1px solid #808080;text-align: center;padding: 10px 0;}
.pd_qs1 ul li img{width: 10px; height: 8px;margin-left: 6px;vertical-align: middle;}
.pd_qs1 ul li.on{border-color: #FF6E0E; color: #FF6E0E; font-weight: 500; font-size: 16px;}
.pd_qs1 ul li.off{border-color: #BDBDBD; color: #BDBDBD; font-weight: 500; font-size: 16px;}

.pd_qs2 ul{display: flex; justify-content: space-between;}
.pd_qs2 ul li{width: 48%; box-sizing: border-box;border-radius:22px; border: 1px solid #808080;text-align: center;padding: 10px 0;}
.pd_qs2 ul li img{width: 10px; height: 8px;margin-left: 6px;vertical-align: middle;}
.pd_qs2 ul li.on{border-color: #FF6E0E; color: #FF6E0E; font-weight: 500; font-size: 16px;}
.pd_qs2 ul li.off{border-color: #999999; color: #999999; }
.pd_plus_pic ul {display: flex; justify-content: space-between;}
.pd_plus_pic ul li{width: 49%;text-align: center;font-size: 12px;}
.pd_plus_pic ul li .img_add{border: 1px solid #ddd;width: 100%; background-color: #fff;margin:0 auto 4px;}
.img_add .pic_camera{width: 100%;}
.pd_etc_memo textarea{width: 100%; border: 1px solid #ddd; background-color: #fff;padding: 5px 10px; height: 100px;color: #999999;}
/* ==============================
    kbtrade_U I_06 매장선택
============================== */

.store_tit{font-size: 20px; font-weight: 500;  padding-bottom: 0px;color: #222222;}
.store_txt{ font-size: 16px; font-weight: 500;color: #777777;}
.store_sub_tit{font-size: 16px; font-weight: 500; color: #333;margin-bottom: 8px;}
.store_find{border: 1px solid #616161;padding: 10px 0; text-align: center; margin-top:28px ;margin-bottom: 50px;font-weight: 500;}
.store_re{border-top: 1px solid #DBDBDB;  font-size: 12px; color:#777777 ;margin-bottom: 40px;padding: 9px 0px; position: relative;}
.store_re p{margin-left: 22px;}
.store_re span{font-size: 16px; color: #333; font-weight: 500;}
.store_re .spoqa{font-size: 12px; color:#777777 ;font-weight: 700;}
.ic_place{width: 18px;vertical-align: text-bottom; margin-right: 4px;}
.ic_check{width: 22px; height: 22px; background-color: #ff6e0e; border-radius: 50%;display: flex;justify-content: center; align-items: center; position: absolute;top:22px; right: 10px;}
.ic_check img{width: 10px; height: 7px;}
.store_date{border: 1px solid #EAEAEA; background-color: #fff; padding: 11px 12px;margin-top: 6px; font-size: 16px; color: #98999A; font-weight: 500;position: relative; margin-bottom: 13px;}
.store_date::after{content: ""; display: block; background: url(../images/ic_cal.png); width: 23px; height: 23px; background-size: contain; position: absolute;right: 21px;top: 30%;}
.store_time{border: 1px solid #EAEAEA; background-color: #fff; padding: 11px 12px;margin-top: 6px; font-size: 16px; color: #98999A; font-weight: 500;position: relative;}
.store_time::after{content: ""; display: block; background: url(../images/ic_con_down.png); width: 8px; height: 6px; background-size: contain; position: absolute;right: 21px;top: 50%;}

/* ==============================
    kbtrade_UI_08 제품판매접수완료
============================== */
.pd_info {margin: 28px 0; border-top: 1px solid #92856B;}
.pd_info table{  border-collapse: collapse; width: 100%;}
.pd_info tr{border-bottom: 1px solid #F4ECDE; width: 100%;}
.pd_info tr td{padding-bottom: 12px;padding: 12px 0;line-height: 14px;}
.pd_info .info1{font-size: 14px;font-weight: 500; color: #666666;padding-right: 30px;display: flex; align-items: flex-start;}
.pd_info .eximg{display: flex; align-items: center;margin-top: 12px;}
.pd_info .eximg img{width: 68px; border: 1px solid #DBDBDB;margin-right: 9px;}
.pd_info .info2{font-size: 16px;font-weight: 400;}
.pd_info .info2.bd{font-weight: 500;}
.pd_info .info2.bd.lh{line-height: normal;}
.pd_info .info2.bd span{font-weight: 400;}
.pd_info .info2 .info2_ad{font-size:12px; color: #777777;}
.pd_info .info3 ul li{font-size: 12px; font-weight: 400;text-indent: -10px;margin-left: 4px;color: #777777;line-height: normal;}
.pd_info .info3 ul li::before{content: "ㆍ";}
.pd_noti_tit{color: #666; font-size: 14px; font-weight: 500;margin-bottom: 9px;}
.pd_notice ul li{font-weight: 300; font-size: 12px;text-indent: -10px;margin-left: 4px;}
.pd_notice ul li::before{content: "ㆍ";}

/* ==============================
    kbtrade_UI_09 제품리스트
============================== */
.list_wrap,.none_wrap,.gallery_wrap{width: 100%; height: 100vh;}
.trade_pd_wrap{background-color: #FAF6EF; color: #333; display: flex; align-items: center; justify-content: space-between; padding:17px 15px 21px;font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important;}
.trade_pd_wrap span{font-family: 'Noto Sans KR' ;}
.trade_pd_wrap p{font-size: 12px;}
.trade_pd_wrap .input_btn{border: 1px solid #222222;font-size: 12px; background-color: #FAF6EF; padding: 7px 6px; border-radius: 22px; color: #222222;}
.trade_pd_wrap p,.trade_pd_wrap h3 {margin: 0;}

.menu_bar{position: relative; padding:12px 16px 14px; font-size: 12px; display: flex;align-items: center;background-color:#FFFCF9; border: 1px solid #EAEAEA; justify-content: flex-end;}
.menu_bar img{height: 14px;vertical-align: top;}
.menu_bar span{font-weight: 700;}
.menu_bar .bar_list1{position: absolute; left: 16px;}
.menu_bar .bar_check{display: flex; align-items: center;}
.nextbar::after{width: 1px; height: 14px; background-color: #ddd; content: ""; display: flex; justify-content: center; align-items: center; float: right;margin:0 11px;}

.menu_bar2{padding:7px 15px; font-size: 12px; display: flex;align-items: center;background-color:#FFF; border-bottom: 1px solid #f5f5f5; justify-content: space-between;}
.bar_num span{font-weight: 700;}
.bar_etc{color: #666666; font-size: 10px; display: flex;align-items: center;position: relative;}
.bar_etc::before{content: "*"; display: block;position: absolute;top: 2px; left: -6px;}
.product_wrap{font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important; }
.product_wrap span{font-family: 'Noto Sans KR' ;}
.product_wrap p,.product_wrap h3 {margin: 0;}

.product_list{display: flex; flex-direction: column;}

.list_box{display: flex; position: relative;padding: 14px 16px 14px; border-bottom: 1px solid #F4ECDE;width: 100%;}

.list_box_l{position: relative;width: 80px; height: 80px; margin-right: 16px;}
.list_box_l img{width: 100%;border: 1px solid #F4F4F4; }
.list_box_l .sell_success {background: rgba(0,0,0, 0.67);position: absolute; top: 0; left: 0;width: 100%; height: 100%;display: flex; justify-content: center; align-items: center;font-size: 12px; font-weight: 500; color: #fff;} 

.list_box_r .list_box_brand{font-size: 12px;line-height: 20px;}
.list_box_r .list_box_name{font-size: 14px; font-weight: 300;line-height: 16px;margin-bottom: 3px;line-height: 20px;}
.list_box_r .list_box_price{font-weight: 700; font-size: 14px;line-height: 20px;}
.list_box_r .list_box_etc{font-size:11px ; color: #666666; display: flex; align-items: center;margin-top: 3px;}
.list_box_r .list_box_etc span{color: #333;margin-left: 1px;font-weight: 500;}
.list_box_r .list_box_etc .spoqa::after{width: 1px; height: 14px; background-color: #ddd; content: ""; display: flex; align-items: center; float: right;margin: 0 7px;}
.list_box_r .list_box_date{font-size: 10px; color:#959595 ;position: absolute; top: 11px; right: 20px;}

.product_gallery{display: flex;padding: 16px 16px; justify-content: space-between;}
.product_gallery .gallery_box{display: flex; flex-direction: column;width: 47%;padding-bottom: 8px;}
.product_gallery .gallery_box img{width: 100%;border: 1px solid #F4F4F4;}
.product_gallery .gallery_box .gallery_box_img{ position: relative;margin-bottom: 10px;}
.product_gallery .gallery_box .sell_success {background: rgba(0,0,0, 0.67);position: absolute; top: 0; left: 0;width: 100%; height: 100%;display: flex; justify-content: center; align-items: center;font-size: 12px; font-weight: 500; color: #fff;} 


.product_gallery .gallery_box .gallery_box_brand{font-size: 12px;}
.product_gallery .gallery_box .gallery_box_name{font-size: 14px; font-weight: 300;}
.product_gallery .gallery_box .gallery_box_price{font-weight: 700; font-size: 14px;}

.product_none{padding-top: 68px; text-align: center;font-size: 14px;}


/* ==============================
    kbtrade_UI_10,11 필터
============================== */
.trade_wrap_bk .pd_list_select{width:100%;position: fixed; bottom: 0;background-color: #fff;border-radius: 16px 16px 0 0; overflow: hidden;}
.trade_wrap_bk .pd_list_select .select_tit{position: relative; background-color: #F5F5F5;}
.trade_wrap_bk .pd_list_select .select_tit h3{font-size: 16px; padding: 18px 0 ; margin: 0;text-align: center;}
.trade_wrap_bk .pd_list_select .select_tit img{width: 18px; height: 18px; position: absolute;top: 22px; right: 19px;}
.trade_wrap_bk .pd_list_select ul{background-color: #fff;display: flex; flex-flow: wrap row; padding: 16px 12px;}
.trade_wrap_bk .pd_list_select ul li{ padding: 10px 17px; width: 100%; box-sizing: border-box; border-bottom: 1px solid #EAEAEA;}
.trade_wrap_bk .pd_list_select ul li:last-child{border: 0px;}
.trade_wrap_bk .pd_list_select ul li.on {font-weight: 700;}
.trade_wrap_bk .pd_list_select ul li.off {color: #999999;}
.trade_wrap_bk .pd_list_select ul li.on .ck_cir{background-color: #FF6E0E;}
.trade_wrap_bk .pd_list_select ul li .ck_cir{width: 20px; height: 20px; float: right; border-radius: 50%; background-color:#DBDBDB ; display: flex; justify-content: center; align-items: center; margin-right: 8px;}
.trade_wrap_bk .pd_list_select ul li .ck_cir img{width: 10px;}
.trade_wrap_bk .pd_list_select .ok_btn{position: relative;}

/* ==============================
    kbtrade_UI_12 상품 상세
============================== */

.detail_wrap{color: #333;font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important;padding-bottom: 48px;border-top: 1px solid #E0E0E0;}
.detail_wrap span{font-family: 'Noto Sans KR' ;}
.detail_wrap p,.detail_wrap h3 {margin: 0;}

.product_img{width: 100%;position: relative;border-bottom: 1px solid #E0E0E0 ;}
.product_img img{width: 100%;}
.product_img .ing_bar{width: 90%;position: absolute; bottom: 29px;left: 5%; height: 1px; background-color: #BDBDBD}
.product_img .ing_bar .per33{background-color:#616161;height: 1px;}

.detail_info{padding: 18px 16px 36px;position: relative;border-bottom: 6px solid #F5F5F5;box-sizing: border-box;}
.detail_info_wrap{padding-bottom: 35px;}
.detail_info .info_brand{font-size: 18px;line-height: 27px;}
.detail_info .info_name_e{font-size: 18px;font-weight: 300;line-height: 27px;}
.detail_info .info_name_f{font-size: 14px; color: #757575;line-height: 20px;position: relative;margin-bottom: 8px;margin-top: 4px;}
.detail_info .info_code{color: #9E9E9E; position: absolute;right: 12px; top: 18px;font-size: 12px;}
.detail_info .info_date{position: relative;color:#888; font-size: 10px;line-height: 20px;margin-bottom: 3px;}
.detail_info .info_name_f .info_price{color: #222222;}
.detail_info .info_date .info_price{color: #888888;}
.detail_info .info_price{position: absolute;right: -6px;bottom: 0; color:#333333 ; font-weight: 700;font-size: 16px;}
.detail_info .pdnm{font-size: 10px;margin-right: 6px;font-weight: 400; font-family: 'Noto Sans KR' !important;}
.detail_info .info_price2{float: right; margin-right: -6px;bottom: 0; color:#666666 ; font-weight: 700;font-size: 16px;}
.detail_info .info_noti{margin-top: 22px; background-color: #F2EEE3; text-align: center;padding: 3px 4px; line-height: 20px;font-size: 12px; color: #6F5F3F;}

.info_tb{margin-top: 31px;}
.info_tb .info_tb_tit{font-size: 14px; font-weight: 500;line-height: 24.5px;margin-bottom: 6px;}
.info_tb table{border-collapse: collapse;width: 100%;}
.info_tb table tr{border-top:1px solid #F4ECDE;}
.info_tb table tr:first-child{border-top:1px solid #92856B}
.info_tb table tr td{padding: 6px 0;font-size: 14px;}
.info_tb table tr td:first-child{color: #777777;width: 33%;}

.detail_menu{border-bottom: 1px solid #EAEAEA;display: flex;padding: 10px 15px;justify-content: space-between;position: relative;height: 44px;}
.detail_menu li{font-size: 16px; font-weight: 400; line-height: 27px;color: #666666;}
.detail_menu li.on{color: #222222; font-weight: 500;}
.detail_menu li.on::after{position: absolute; background-color:#FE7013;height: 2px;width: 60px; content: ""; display: block;bottom: 0;}
.detail_img {padding: 20px 16px;}
.detail_img img{width: 100%;}
.detail_menu2{border-bottom: 1px solid #EAEAEA;display: flex;justify-content: space-between;}
.detail_menu2 li{margin: 10px 15px;font-size: 16px; font-weight: 400; line-height: 27px;color: #666666;width: 50%;text-align: center;position: relative; display: flex;flex-direction: column;justify-content: space-between; align-items: center;}
.detail_menu2 li.on{color: #222222; font-weight: 500;}
.detail_menu2 li.on::after{ background-color:#FE7013;height: 2px;width: 60px; content: ""; display: block;position: absolute;bottom: -10px;}

.detail_data{padding:26px 16px;background-color: #FEFBF7;}
.detail_data table{border-collapse: collapse;width: 100%;}
.detail_data table tr{border-top:1px solid #F4ECDE;}
.detail_data table tr:last-child{border-bottom:1px solid #F4ECDE;}
.detail_data table tr:first-child{border-top:1px solid #92856B}
.detail_data table tr td{padding: 6px 0;font-size: 14px;}
.detail_data table tr td:first-child{color: #777777;width: 33%;}

.pur_btn{position: fixed; bottom: 0;text-align: center;width: 100%;}
.pur_btn ul{display: flex; justify-content: space-between;width: 100%;background-color: #fff;}
.pur_btn ul li{width: 50%;padding: 12px 0;font-size: 16px; font-weight: 700;box-sizing: border-box;}
.pur_btn ul li.on:first-child{border:1px solid #FF6E0E;color: #FF6E0E;background-color: #fff;box-sizing: border-box;height: 48px;}
.pur_btn ul li.off:first-child{border:none;color: #BBBBBB;background-color: #DDDDDD;box-sizing: border-box;height: 48px;}
.pur_btn ul li.on:last-child{border:1px solid #FF6E0E;background-color: #FF6E0E;color: #fff;height: 48px;}
.pur_btn ul li.ogg:last-child{border:none;background-color: #BE520A;color: #DFA985;height: 48px;}
.pur_btn.ok{padding: 12px 0;font-size: 16px; font-weight: 700;color: #777777; background-color: #DDDDDD;height: 48px;}

/* ==============================
    kbtrade_UI_13 비밀번호
============================== */
.password_wrap{background-color: #DFDCDC;width: 100%; height: 100%;position: relative;display: flex; flex-direction: column; justify-content: space-between;}
.password_wrap p, .password_wrap h3{margin: 0;}
.password_view_wrap{position: relative;width: 100%;height: 100%; }
.password_view{background-color: #FEFBF7; border-radius: 0 0 18px 18px;position: absolute;top: 0; left: 0; width: 100%; height: 100%;}
.password_view img{height: 14px; position: absolute; right:15px; top: 15px;}
.password_view p{padding-top: 35%;text-align: center;font-size: 18px; font-weight: 500;}
.password_num ul {padding: 9% 13% 25%;width: 100%; box-sizing: border-box; display:flex;justify-content: center;}
.password_num ul li{margin-right: 4px; background-color:#F0EDE6 ; border: 1px solid #E0E0E0;width: 40px; height: 40px;display: flex; justify-content: center; align-items: center;}
.password_num ul li:last-child{margin-right: 0;}
.password_num ul li.on{background-color: #BBAC8E;}
.password_num ul li.on::after{content: ""; background: url(../images/ic_pass.png);display: block; height: 24px;width: 24px; background-size: 100%;}

.password_btn{position: absolute; bottom: 24px; padding: 12px 0; width: calc(100% - 100px); font-size: 16px; background-color:#DDDDDD ; color:#BBBBBB ;font-weight: 700; text-align: center;border: none;margin: auto 50px;}
.password_btn.on{background-color: #ff6e0e; color: #fff;}

.password_key_wrap{position: relative;width: 100%;height: 37%;}
.password_key{position: absolute;top: 0; left: 0; width: 100%; height: 100%;}
.password_key ul{background-color: #DFDCDC;width: 100%;display: flex;height: 25%;justify-content: space-between;}
.password_key ul li{padding: 4.5% 0; text-align: center;width: 33.33%; background-color:#F0EDE6 ; 
    border: 1px solid #E0E0E0; font-size: 20px;display: inline-flex;justify-content: center; align-items: center;}
.password_key ul li.ft15{font-size: 15px;}
.password_key ul li img{height: 24px;}
/* ==============================
    kbtrade_UI_14 나의 안심직거래 구매 &판매 탭
============================== */
.mypage_wrap{font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important; }
.mypage_wrap span{font-family: 'Noto Sans KR' ;}
.mypage_wrap p,.mypage_wrap h3 {margin: 0;}

.product_list{display: flex; flex-direction: column;}

.my_list .list_box {padding: 15px 18px 15px;border-bottom: 1px solid #F4ECDE; width: 100%;display: flex;flex-direction: column;}

.my_list .list_box_wrap{display: flex; position: relative; width: 100%;}

.my_list .list_box_l{position: relative;width: 80px; height: 80px; margin-right: 16px;}
.my_list .list_box_l img{width: 100%;border: 1px solid #F4F4F4; }
.my_list .list_box_l .sell_success {background: rgba(0,0,0, 0.67);position: absolute; top: 0; left: 0;width: 100%; height: 100%;display: flex; justify-content: center; align-items: center;font-size: 12px; font-weight: 500; color: #fff;} 

.my_list .list_box_r .list_box_brand{font-size: 14px;line-height: 20px;}
.my_list .list_box_r .list_box_name{font-size: 14px; font-weight: 300;line-height: 16px;margin-bottom: 3px;line-height: 20px;}
.my_list .list_box_r .list_box_price{font-weight: 700; font-size: 14px;line-height: 20px;}
.my_list .list_box_r .list_box_etc{font-size:11px ; color: #666666; display: flex; align-items: center;margin-top: 3px;}
.my_list .list_box_r .list_box_etc span{margin-left: 1px;color: #666;}
.my_list .list_box_r .list_box_etc .spoqa::after{display: none;}

.my_list .list_box .list_box_btn{width: 100%;margin-top: 13px;}
.my_list .list_box .list_box_btn button{width: 48%; border: 1px solid #E4E4E4; background-color: #fff;padding:8px 0;}

.my_sell, .my_buy{border-top : 6px solid #F5F5F5;}
.my_buy .my_buy_box{padding:12px 0 0;}
.buy_state{display: flex;justify-content: space-between;padding: 0 17px;align-items: center;font-size: 14px; font-weight: 500;}


.my_sell .my_sell_box{padding:12px 0 0;}
.buy_state.view{color: #999999;}
.sell_state{display: flex;justify-content: space-between;padding: 0 17px;align-items: center;font-size: 14px; font-weight: 500;}
.sell_state.before{color: #FFB100;}
.sell_state.ing{color: #FD8F47;}
.sell_state.after{color: #999;}
.sell_state .list_box_detail{font-size: 12px; color:#222222;}

.my_menu ul{border-bottom: 2px solid #EAEAEA;display: flex;justify-content: space-between;}
.my_menu li{margin: 10px 0px;font-size: 16px; font-weight: 400; line-height: 27px;color: #666666;width: 50%;text-align: center;position: relative; display: flex;flex-direction: column;justify-content: space-between; align-items: center;}
.my_menu li.on{color: #222222; font-weight: 500;}
.my_menu li.on::after{ background-color:#FE7013;height: 2px;width: 28px; content: ""; display: block;position: absolute;bottom: -10px;}

.my_buy.none{text-align: center;}
.my_buy.none img{height: 48px;margin-top: 63px;}
.my_buy.none p{font-size:14px;margin-top: 3px;}

.my_sell.none{text-align: center;}
.my_sell.none img{height: 48px;margin-top: 63px;}
.my_sell.none p{font-size:14px;margin-top: 3px;}
/* ==============================
    kbtrade_UI_14_2,17_2 결제하기 창
============================== */

.trade_wrap_bk .pd_pay{width:100%;position: fixed; bottom: 0;background-color: #fff;border-radius: 16px 16px 0 0; overflow: hidden;}
.trade_wrap_bk .pd_pay .pay_tit{position: relative; background-color: #F5F5F5;}
.trade_wrap_bk .pd_pay .pay_tit h3{font-size: 18px; padding: 18px 0 ; margin: 0;text-align: center;}
.trade_wrap_bk .pd_pay .pay_tit img{width: 18px; height: 18px; position: absolute;top: 22px; right: 19px;}
.trade_wrap_bk .pd_pay .pay_list{padding: 16px 12px;}
.trade_wrap_bk .pd_pay ul{background-color: #fff;display: flex; flex-flow: wrap row;border-bottom: 1px solid #EAEAEA;}
.trade_wrap_bk .pd_pay ul.ulbd{border: 0px;}
.trade_wrap_bk .pd_pay ul li{ padding: 10px 17px; width: 50%; box-sizing: border-box;}
.trade_wrap_bk .pd_pay ul li:last-child{text-align: right;}
.trade_wrap_bk .pd_pay ul li .ck_cir{width: 20px; height: 20px; float: left; border-radius: 50%; background-color:#DBDBDB ; display: flex; justify-content: center; align-items: center; margin-right: 8px;}
.trade_wrap_bk .pd_pay ul li .ck_cir img{width: 10px;}
.trade_wrap_bk .pd_pay .ok_btn{position: relative;}

.trade_wrap_bk .pd_pay ul.ulbd li:first-child{font-weight: 500;font-size:16px ;}
.trade_wrap_bk .pd_pay ul.ulbd li:last-child{font-weight: 400;font-size:20px ;}
.trade_wrap_bk .pd_pay ul.ulbd li:last-child .spoqa{font-weight: 700;}

.trade_wrap_bk .pd_pay_cancel{width:100%;position: fixed; bottom: 0;background-color: #fff;border-radius: 16px 16px 0 0; overflow: hidden;padding-bottom: 48px;}
.trade_wrap_bk .pd_pay_cancel .pay_tit{position: relative; background-color: #F5F5F5;}
.trade_wrap_bk .pd_pay_cancel .pay_tit h3{font-size: 18px; padding: 18px 0 ; margin: 0;text-align: center;}
.trade_wrap_bk .pd_pay_cancel .pay_tit img{width: 18px; height: 18px; position: absolute;top: 22px; right: 19px;}
.trade_wrap_bk .pd_pay_cancel .pay_cancel_list{padding: 16px 12px;}
.trade_wrap_bk .pd_pay_cancel ul{background-color: #fff;display: flex; flex-flow: wrap row;border-bottom: 1px solid #EAEAEA;}
.trade_wrap_bk .pd_pay_cancel ul li{ padding: 10px 17px; width: 50%; box-sizing: border-box;}
.trade_wrap_bk .pd_pay_cancel ul:last-child{border-bottom: 0px;}
.trade_wrap_bk .pd_pay_cancel ul:first-child li:last-child{ padding: 10px 17px 26px; width: 100%; box-sizing: border-box;display: flex;flex-direction: column;text-align: left;font-size: 18px;font-weight: 500;}
.trade_wrap_bk .pd_pay_cancel ul:first-child li:last-child span{font-size:14px ;color: #444;font-weight: 400;margin-top: 8px;}
.trade_wrap_bk .pd_pay_cancel ul li:last-child{text-align: right;}
.trade_wrap_bk .pd_pay_cancel ul li .ck_cir{width: 20px; height: 20px; float: left; border-radius: 50%; background-color:#DBDBDB ; display: flex; justify-content: center; align-items: center; margin-right: 8px;}
.trade_wrap_bk .pd_pay_cancel ul li .ck_cir img{width: 10px;}
.trade_wrap_bk .pd_pay_cancel .ok_btn{position: relative;}

.trade_wrap_bk .pd_pay_cancel ul.ulbd li:first-child{font-weight: 400;font-size:16px ;}
.trade_wrap_bk .pd_pay_cancel ul.ulbd li:last-child{font-weight: 400;font-size:14px ;}
.trade_wrap_bk .pd_pay_cancel ul.ulbd li:last-child .spoqa{font-weight: 700;font-size:20px ;}
.pay_btn{width: 100%;margin-top: 13px ;display: flex;justify-content: space-around;}
.pay_btn button{width: 48%;border: none;color: #fff;padding: 11px 0;font-size: 16px; font-weight: 700;}

.pay_btn button.cancel{background-color: #757575;}
.pay_btn button.ok{background-color: #ff6e0e;}
/* ==============================
    kbtrade_UI_15 접수신청
============================== */
.receipt_wrap{font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important; 
    background-size: cover;}
.receipt_wrap span{font-family: 'Noto Sans KR' ;}
.receipt_wrap p,.receipt_wrap h3 {margin: 0;}

.receipt{background-color: #FAF6EF;text-align: center;padding: 24px 16px 33px;border-top: 1px solid #E0E0E0; border-bottom: 1px solid #E0E0E0;}
.receipt.success{border-bottom: 0;}
.receipt.product{border-bottom: 0;}
.receipt img{height: 48px;}
.receipt p{font-size: 20px; font-weight: 500;letter-spacing: -0.5px;}
.receipt span{font-size: 16px; color: #777777;}
.receipt span.orgcl{font-size: 20px; color: #DD6F2E;}

.receipt_info td:first-child{vertical-align: top;}
.receipt_table{border-radius:40px; border: 1px solid #F4ECDE;background-color: #FFFBF5;margin: 20px auto 0;padding: 19px 37px;height: 75px; position: relative;width: 100%; }
.receipt_table ul{width: 100%;display: flex;justify-content: space-between;box-sizing: border-box;}
.receipt_table ul li{font-size: 12px;}
.receipt_table ul li .dot{ background-color: #fff; width: 16px; height: 16px;margin: 0 auto; border-radius: 50%;margin-bottom: 4px;position: relative;}
.receipt_table ul li .dot img{width: 100%; height: 100%;z-index: 999;position: relative;}
.receipt_table ul li.on .dot img{z-index: 999;position: relative;}
.receipt_table ul li.on{color: #FF6E0E; }
.receipt_table ul li{position: absolute;top:30%;color: #999999;}
.receipt_table ul li.dot1{position: absolute; left: 45%; margin-left: -100px;}
.receipt_table ul li.dot2{position: absolute; left: 45%;}
.receipt_table ul li.dot3{position: absolute; left: 45%; margin-left: 70px;}
.receipt_table ul li.on .dot::after {content: ""; position:absolute;width:88px;height:1.4px;background:#ff6e0e;top:50%;left: 0; z-index: 1;}
.receipt_table ul li.on:nth-child(2) .dot::after {content: ""; position:absolute;width:88px;height:1.4px;background:#ff6e0e;top:50%;left: 0; z-index: 1;}
.receipt_table ul li.on:last-child .dot::after {content: ""; display: none;}
.receipt_table ul li .dot.center::after {content: ""; position:absolute;width:88px;height:1.4px;background:#E0E0E0;top:50%;right: 0;}
.receipt_table ul li .dot.center::before {content: ""; position:absolute;width:88px;height:1.4px;background:#E0E0E0;top:50%;left: 0;}

.receipt_num{display: flex; justify-content: space-between; align-items: center;padding: 12px 15px;border-bottom: 1px solid #F4ECDE;background-color: #FFFCF9;}
.receipt_num h3{font-size: 16px;line-height: 24px;} 
.receipt_num .receipt_date{font-size: 14px; color: #999999; font-weight: 400;}
.receipt_num .cancel_btn{text-decoration: underline; text-underline-position: under;font-size: 12px;}

.bar{border-bottom: 6px solid #F5F5F5;}

.receipt_info{padding: 18px 16px;border-bottom: 1px solid #F4ECDE;background-color: #FFFCF9;}
.receipt_info h3{margin-bottom: 9px;font-size: 16px;font-weight: 500;letter-spacing: -0.5px;}
.receipt_info table{border-collapse: collapse;width: 100%;}
.receipt_info table tr{border-top: 1px solid #F4ECDE;}
.receipt_info table tr:first-child{border-top: 1px solid #92856B;}
.receipt_info table tr td{padding: 8px 0;}
.receipt_info table tr td:first-child{color:#666666 ; font-size: 14px;width: 23%;}
.receipt_info table tr td:last-child{font-weight: 500;font-size: 16px;}
.receipt_info table tr td:last-child .spoqa{font-weight: 400;}
.receipt_info table tr td.spoqa{font-weight: 400;}
.receipt_info table tr td img{height: 18px;vertical-align: top;margin-right: 2px;}
.receipt_info table tr td .grad{color: #777777; font-size: 12px;margin-bottom: 10px;}
.receipt_info table tr td .orgbt{border: 1px solid #FF6E0E;padding: 3px 7px 3px; color: #FF6E0E;font-size: 12px;display: flex; align-items: center;width: 110px;}

.receipt_history{padding: 18px 16px 88px;background-color: #FFFCF9;}
.receipt_history h3{margin-bottom: 9px;font-size: 16px;font-weight: 500;letter-spacing: -0.5px;}
.receipt_history ul{border-top: 1px solid #92856B;display: flex;justify-content: space-between; align-items: center;}
.receipt_history li{font-size: 16px; font-weight: 400; line-height: 27px;color: #666666;padding: 12px 0;width: 60px;text-align: center;}
.receipt_history li.on{color: #222222; font-weight: 500;position: relative;}
.receipt_history li.on::after{position: absolute; background-color:#FE7013;height: 2px;width: 60px; content: ""; display: block;bottom: 0;}
.receipt_history table {border-collapse: collapse;width: 100%;}
.receipt_history table tr{border-top: 1px solid #F4ECDE;}
.receipt_history table tr td{padding: 9px 0;}
.receipt_history table tr td:first-child{text-align: left;color: #444444; font-size: 14px;}
.receipt_history table tr td:last-child{text-align: right;color: #444444; font-size: 14px;}

.receipt_fail{display: flex;flex-direction: column;align-items: center;justify-content: center; padding:15px 30px 24px;border-bottom: 1px solid #F4ECDE;background-color: #FFFCF9;}
.receipt_fail img{height: 48px;}
.receipt_fail p{font-size: 16px;font-weight: 500;margin-bottom: 9px;}
.receipt_fail span{font-size: 12px; color: #777777;font-weight: 400;}
.receipt_fail button{width: 100%; border: none;background-color:#222222 ;color: #fff;padding: 12px 0;font-size: 16px;font-weight: 700;margin-top: 22px;}

.receipt_price{background-color: #FF6E0E;padding: 10px 30px 24px;color: #fff;border-bottom:1px solid #DDDDDD;}
.receipt_price_ul{display: flex; justify-content: space-between;}
.receipt_price_ul li{font-size: 16px;font-weight: 500;}
.receipt_price_ul li .spoqa{font-weight: 700;font-size: 24px;}
.receipt_price_ul li:last-child{font-size: 20px;font-weight: 400;}
.receipt_price > p{text-align: right; font-size: 12px;}
.receipt_price_bt {display: flex;margin-top: 22px;}
.receipt_price_bt li{width: 50%;display: flex; justify-content: center;align-items: center;}
.receipt_price_bt li button{text-align: center;font-size: 14px; border: 1px solid #fff;background-color: #FF6E0E;border-radius: 20px;padding:4px 15px; color: #fff;}
.receipt_price_bt li:last-child button{text-align: center; border: 1px solid #fff;background-color: #fff;border-radius: 20px;padding:4px 15px; color: #ff6e0e;font-weight: 700;}
.receipt_price .receipt_price_bt li.disabled{color: #FFB787;}
.receipt_price .receipt_price_bt li.disabled button{font-size: 14px; text-align: center; border: 1px solid #FFB787;background-color: #FF6E0E;border-radius: 20px;padding:4px 15px; color: #FFB787;}
.receipt_price .receipt_price_bt li.disabled:last-child button{text-align: center; border: 1px solid #BE520A;background-color: #BE520A;border-radius: 20px;padding:4px 15px; color: #DFA985;font-weight: 700;}


/* ==============================
    kbtrade_UI_16,17,18 접수신청
============================== */

.receipt_price_ul.line{margin-bottom: 6px;}
.receipt_price_ul.last{border-top: 1px solid rgba(244,236,222,0.2);padding-top: 12px;}

.receipt_price.product{background-color: #FF6E0E;padding: 10px 30px 24px;color:#FFE6D5;border-bottom:1px solid #DDDDDD;}
.receipt_price.product .receipt_price_ul{display: flex; justify-content: space-between;}
.receipt_price.product .receipt_price_ul li{font-size: 16px;font-weight: 500;}
.receipt_price.product .receipt_price_ul li .spoqa{font-weight: 700;font-size: 20px;}
.receipt_price.product .receipt_price_ul li:last-child{font-size: 18px;font-weight: 400;}
.receipt_price.product .receipt_sell_price{display: flex; justify-content: space-between;color: #fff; border-top: 1px solid rgba(244, 236, 222, 0.2);margin-top: 10px;}
.receipt_price.product .receipt_sell_price li{font-size: 16px;font-weight: 500;padding-top: 10px; display: flex;align-items: center;}
.receipt_price.product .receipt_sell_price li .revise{margin-left: 5px;font-size: 10px; text-decoration: underline; text-underline-position: under;}
.receipt_price.product .receipt_sell_price li .spoqa{font-weight: 700;font-size: 24px;}
.receipt_price.product .receipt_sell_price li:last-child{font-size: 20px;font-weight: 400;}
.receipt_price.product .receipt_price > p{text-align: right; font-size: 12px;color: #fff;}
.receipt_price.product .receipt_price_bt {display: flex;margin-top: 22px;}
.receipt_price.product .receipt_price_bt li{width: 50%;display: flex; justify-content: center;align-items: center; color: #fff;}
.receipt_price.product .receipt_price_bt li.disabled{color: #FFB787;}
.receipt_price.product .receipt_price_bt li.disabled button{font-size: 14px; text-align: center; border: 1px solid #FFB787;background-color: #FF6E0E;border-radius: 20px;padding:4px 15px; color: #FFB787;}
.receipt_price.product .receipt_price_bt li.disabled:last-child button{text-align: center; border: 1px solid #BE520A;background-color: #BE520A;border-radius: 20px;padding:4px 15px; color: #DFA985;font-weight: 700;}

.receipt_info.product{padding: 18px 16px;border-bottom: 1px solid #F4ECDE;background-color: #FFFCF9;}
.receipt_info.product h3{margin-bottom: 9px;font-size: 16px;font-weight: 500;letter-spacing: -0.5px;}
.receipt_info.product table{border-collapse: collapse;width: 100%;}
.receipt_info.product table tr{border-top: 1px solid #F4ECDE;}
.receipt_info.product table tr:first-child{border-top: 1px solid #92856B;}
.receipt_info.product table tr td{padding: 8px 0;}
.receipt_info.product table tr td:first-child{color:#666666 ; font-size: 14px;width: 23%;}
.receipt_info.product table tr td:last-child{font-weight: 500;font-size: 16px;}
.receipt_info.product table tr td:last-child .spoqa{font-weight: 400;}
.receipt_info.product table tr td.spoqa{font-weight: 400;}
.receipt_info.product table tr td.ftbd{font-weight: 700;}
.receipt_info.product table tr td img{height: 18px;vertical-align: top;margin-right: 2px;}
.receipt_info.product table tr td .grad{color: #777777; font-size: 12px;margin-bottom: 10px;}
.receipt_info.product table tr td .orgbt{border: 1px solid #FF6E0E;padding: 3px 7px 3px; color: #FF6E0E;font-size: 12px;display: flex; align-items: center;width: 110px;}
.receipt_info.product table tr td.toptd{vertical-align: top;}



.receipt_info.acco .receipt_info.acco .vertical [type=radio]:checked ~ label ~ .content .btn_cx_up,
.receipt_info.acco .vertical [type=checkbox]:checked ~ label ~ .content .btn_cx_up {position:absolute;top:23px;right:5px;width:30px;height:10px;background: #fff url(../images/up.png) no-repeat 0 0;
    background-size: auto 8px;z-index:2;}
.receipt_info.acco  .vertical  .btn_cx_down {position:absolute;top:23px;right:5px;width:30px;height:10px;background: #fff url(../images/down.png) no-repeat 0 0;
     background-size: auto 8px;z-index:1;}
.receipt_info.acco .accordion ul li label::after {display: none;}
.receipt_info.acco{padding: 0;border-bottom: 1px solid #F4ECDE;background-color: #FFFCF9;}
.receipt_info.acco .vertical ul li label {
    padding: 0px 16px;
}
.receipt_info.acco .vertical [type=radio]:checked ~ label ~ .content,.receipt_info.acco .vertical [type=checkbox]:checked ~ label ~ .content {
    padding: 0 16px 18px;
    overflow-y: scroll; 
    height: 230px;         
}
.receipt_info.acco .vertical >ul >li{margin: 0;box-shadow: none;}
.receipt_info.acco h3{margin-bottom: 9px;font-size: 16px;font-weight: 500;letter-spacing: -0.5px;}
.receipt_info.acco table{border-collapse: collapse;width: 100%;}
.receipt_info.acco table tr{border-top: 1px solid #F4ECDE;}
.receipt_info.acco table tr:first-child{border-top: 1px solid #92856B;}
.receipt_info.acco table tr td{padding: 8px 0;}
.receipt_info.acco table tr td:first-child{color:#666666 ; font-size: 14px;width: 23%;}
.receipt_info.acco table tr td:last-child{font-weight: 500;font-size: 16px;}
.receipt_info.acco table tr td:last-child .spoqa{font-weight: 400;}
.receipt_info.acco table tr td.spoqa{font-weight: 400;}
.receipt_info.acco table tr td.ftbd{font-weight: 700;}
.receipt_info.acco table tr td img{height: 18px;vertical-align: top;margin-right: 2px;}
.receipt_info.acco table tr td .grad{color: #777777; font-size: 12px;margin-bottom: 10px;}
.receipt_info.acco table tr td .orgbt{border: 1px solid #FF6E0E;padding: 3px 7px 3px; color: #FF6E0E;font-size: 12px;display: flex; align-items: center;width: 110px;}

.receipt_ing{padding: 18px 16px;border-bottom: 1px solid #F4ECDE;background-color: #FFFCF9;}
.receipt_ing h3{font-weight: 500; font-size: 16px;}
.receipt_ing li{text-align: center;}
.receipt_ing li .spoqa{font-size: 10px; letter-spacing: -1.2px;}

.receipt_ing .receipt_table{border-radius:40px; border: 1px solid #F4ECDE;background-color: #FFFBF5;margin: 20px auto 0;padding: 19px 37px;height: 89px; position: relative;width: 100%; }
.receipt_ing .receipt_table ul li{top:20%;
    display: flex;
    flex-direction: column;}
.receipt_ing .receipt_table ul li.dot2{position: absolute; left: 41%;}

.receipt_history.product{padding: 18px 16px 40px;background-color: #FFFCF9;}
.receipt_history.product li.wd85{width: 85px;}
.receipt_history.product li.wd85.on::after{position: absolute; background-color:#FE7013;height: 2px;width: 85px; content: ""; display: block;bottom: 0;}

.receipt.return{border-bottom: 1px solid #F4ECDE;display: flex;flex-direction: column; justify-content: center; align-items: center;}
.receipt.return  p{padding-bottom: 7px;}
.receipt.return span{font-size: 14px;}
.receipt.return .return_price{color: #222;padding-top: 14px;position: relative;z-index: 1;}
.receipt.return .return_price span{font-weight: 700;color: #222;}
.receipt.return .return_price::after{width: 100%;height:8px;position: absolute;bottom: 0; display: block;background-color: rgba(255,179,130, 0.50);content: "";z-index: -1;}

.return_btn{background-color: #FFFCF9;padding: 19px 16px 40px;text-align: center;}
.return_btn p{font-size:15px ;}
.return_btn .return_btn_wrap{width: 100%;margin-top: 21px;display: flex;justify-content: space-between;}
.return_btn .return_btn_wrap button{width: 48%;padding: 11px 0 9px; border: 1px solid #ff6e0e;background-color: #fffcf9; color: #ff6e0e;font-size: 16; font-weight: 700;}
.return_btn .return_btn_wrap button:last-child{background-color: #ff6e0e; color: #fff;}

/* ==============================
    kbtrade_UI_19 감정결과 상세보기
============================== */
.receipt_info.re{border-top: 1px solid #E0E0E0;}
.receipt_wrap{background-color: #FFFCF9;
    background-size: cover;}
.receipt_info.re td:first-child{width: 38%;vertical-align: top;margin-right: 10px;}
.receipt_info.re tr:last-child td:first-child{letter-spacing: -0.6px;}
.receipt_info.re td ul{display: flex; justify-content: space-between;}
.receipt_info.re td ul:first-child{margin-bottom: 14px;}
.receipt_info.re td ul li {display: flex; flex-direction: column;text-align: center;font-size: 10px; color: #656667;}
.receipt_info.re td ul li div{width: 64px;height: 64px;display: flex;justify-content: center; align-items: center;border: 1px solid #DBDBDB;margin-bottom: 4px;}
.receipt_info.re td ul li img{width: 100%;height: 100%;}
.receipt_info.re td ul li img.img_none{height: 48px;width: 48px;}
.receipt_info.re table tr td:last-child .spoqa{font-weight: 700;}

.receipt_info.app{border-bottom: 0px;}
.receipt_info.app table tr:last-child {border-bottom: 1px solid #F4ECDE;}
.receipt_info.app td:first-child{width: 38%;vertical-align: top;margin-right: 10px;}
.receipt_info.app table tr td:last-child {font-weight: 400;font-size: 16px;}

/* ==============================
    kbtrade_UI_20,21 판매신청
============================== */

.trade_wrap_bk .sell_app{width:100%;position: fixed; bottom: 0;background-color: #fff;border-radius: 16px 16px 0 0; overflow: hidden;padding-bottom: 48px;}
.trade_wrap_bk .sell_app .sell_tit{position: relative; background-color: #F5F5F5;}
.trade_wrap_bk .sell_app .sell_tit h3{font-size: 18px; padding: 18px 0 ; margin: 0;text-align: center;}
.trade_wrap_bk .sell_app .sell_tit img{width: 18px; height: 18px; position: absolute;top: 22px; right: 19px;}
.trade_wrap_bk .sell_app .sell_app_list{padding: 18px 12px;border-bottom: 1px solid #F4ECDE;}
.trade_wrap_bk .sell_app .sell_app_list h3{font-size: 16px; font-weight: 500;margin-top: 8px;margin-bottom: 10px;}
.trade_wrap_bk .sell_app .sell_app_list ul{background-color: #fff;display: flex;justify-content: space-between; align-items: center; border-bottom: 1px solid #EAEAEA;box-sizing: border-box;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(2){border-bottom: 0px solid #EAEAEA;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(2) li:last-child{width: 70%;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(3) li:last-child{color: #999;font-size: 14px;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(3) li:last-child .spoqa{font-size: 16px;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(4){border-bottom: 0px;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(4) li:last-child{font-size: 20px;}
.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(4) li:last-child .spoqa{font-weight: 700}
.trade_wrap_bk .sell_app .sell_app_list ul li{ padding: 10px 4px; box-sizing: border-box;position: relative;}
.trade_wrap_bk .sell_app .sell_app_list ul li input{border: 1px solid #DBDBDB; padding:12px 11px 14px ; color: #999999; font-size: 16px;width: 100%;}
.trade_wrap_bk .sell_app .sell_app_list ul li .won{position: absolute; top: 27px; right: 8px;color: #999999;}
.trade_wrap_bk .sell_app .ok_btn{position: relative;}

.sell_app_noti{background-color: #FFFCF9; padding: 10px 12px 30px;}
.sell_app_noti ul{display: flex;flex-direction: column;}
.sell_app_noti li{font-size: 12px; font-weight: 300; color: #777777;text-indent: -10px; margin-left: 10px;}
.sell_app_noti li::before{content: "ㆍ"; display: block; float: left;margin-right: 10px;}

.sell_app.key{display: flex;flex-direction: column;padding-bottom: 0;}
.sell_app.key .password_key_wrap{position: initial;}
.sell_app.key .password_key_wrap .password_key{position: initial;}
.sell_app.key .password_key_wrap .password_key ul{margin-bottom: 2px;background-color: #F5F5F5;}
.sell_app.key .password_key_wrap .password_key ul li{width: 33%;}
.trade_wrap_bk .sell_app .sell_app_list ul li.on > div{border: 1px solid #DBDBDB; padding:12px 11px 14px ;color: #222;font-weight: 700;text-align: right; padding-right: 21px;font-size: 18px;}
.trade_wrap_bk .sell_app .sell_app_list ul li.on .won{position: absolute; top: 23px; right: 8px;color: #222222;} 

.trade_wrap_bk .sell_app .sell_app_list ul:nth-child(3) li:last-child.on{color: #222222;}
.trade_wrap_bk .sell_app .sell_app_list ul li.off >div{border: 1px solid #DBDBDB; padding:12px 11px 14px ;background-color: #EAEAEA; color: #98999A;
    font-weight: 700;text-align: right; padding-right: 21px;font-size: 18px;}
.trade_wrap_bk .sell_app .sell_app_list ul li.off .won{position: absolute; top: 23px; right: 8px;color: #98999A} 

/* ==============================
    kbtrade_UI_20,21 판매신청
============================== */
.order_wrap{background-color: #FFFCF9; color: #333;padding:16px 16px 88px;font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important;
    background-size: cover;height: 100%;}
    .order_wrap p, .order_wrap h3{margin: 0;}
.store_visit_top{background-color: #FAF6EF;padding: 17px 15px 19px;border-top: 1px solid #e0e0e0;}
.store_visit_top h3{margin: 0; font-size: 12px; font-weight: 500;line-height: 17px;}
.store_visit_top h3 span{font-weight: 700;}
.store_visit_top_sub{background-color: #F0EDE6;padding: 4px 15px 5px;display: flex;font-size: 10px;align-items: center;color: #666666; border-top: 1px solid #eaeaea; border-bottom: 1px solid #eaeaea;}
.store_visit_top_sub img{height: 14px; vertical-align: top; margin-right: 2px;}
.store_visit_top_sub p{margin: 0;line-height:20px ;}
.store_visit_tit{font-size: 16px; letter-spacing: -0.5px; font-weight: 500;}
.store_visit_tit span{color: #ff6e0e;}
.store_visit_txt{ font-size: 16px; font-weight: 500;color: #777777;}
.store_visit_pd table tr td.ft10.clgr{color: #9E9E9E;}
.store_visit_pd{display: flex; border: 1px solid #F4ECDE;padding: 17px 16px 16px;margin-bottom: 26px;}
.store_visit_pd .visit_pd_left{width: 80px; height: 80px;border: 1px solid #E0E0E0;margin-right: 13px;}
.store_visit_pd .visit_pd_left img{width: 100%}
.store_visit_pd .visit_pd_right{width: calc(100% - 100px);}
.store_visit_pd table{border-collapse: collapse;width: 100%;}
.store_visit_pd table .bdbottom{border-bottom: 1px solid #F4ECDE;}
.store_visit_pd table .bdbottom td.ft14{font-size: 14px;margin-bottom: 8px;}
.store_visit_pd table tr {display: flex;justify-content:space-between; align-items: center;}
.store_visit_pd table tr td{display: flex;align-items: center;vertical-align: top   ;}
.store_visit_pd table tr td.ft10{font-size: 10px;padding-top: 4px;color: #666666;}
.store_visit_pd table tr td.ft11{font-size: 11px;padding-top: 4px;color: #666666;}
.store_visit_pd table tr td.ft11 .spoqa{font-size: 12px;}
.store_visit_pd table tr td.ft12{font-size: 12px;padding-top: 7px;font-weight: 700;}
.store_visit_pd table tr td.ft13{font-size: 13px;padding-top: 8px;color: #2E2D2E;font-weight: 700;}
.store_visit_pd table tr td.ft13 .spoqa{font-size: 14px;color: #2E2D2E;}
.store_visit_pd table tr td.ft14{font-size: 14px;}
.store_visit_pd.preview .visit_pd_left{width: 80px; height: 80px;border: 1px solid #E0E0E0;margin-right: 13px;}
.store_visit_pd.preview .visit_pd_left img{width: 100%}
.store_visit_pd.preview .visit_pd_right{width: calc(100% - 100px);}
.store_visit_pd.preview table{border-collapse: collapse;width: 100%;}
.store_visit_pd.preview table .bdbottom{border-bottom: 1px solid #F4ECDE;}
.store_visit_pd.preview table .bdbottom td.ft14{font-size: 14px;margin-bottom: 8px;}
.store_visit_pd.preview table tr {display: flex;justify-content:space-between; align-items: center;}
.store_visit_pd.preview table tr td{display: flex;align-items: center;vertical-align: top   ;}
.store_visit_pd.preview table tr td.ft10{font-size: 10px;padding-top: 4px;color: #999999;}
.store_visit_pd.preview table tr td.ft11{font-size: 11px;padding-top: 4px;color: #999999;}
.store_visit_pd.preview table tr td.ft11 .spoqa{font-size: 12px;}
.store_visit_pd.preview table tr td.ft12{font-size: 12px;padding-top: 7px;}
.store_visit_pd.preview table tr td.ft13{font-size: 13px;padding-top: 8px;color: #999999;}
.store_visit_pd.preview table tr td.ft13 .spoqa{font-size: 14px;color: #999999;}
.store_visit_pd.preview table tr td.ft14{font-size: 14px;}
.store_visit_sub_tit{font-size: 16px; font-weight: 500; color: #333;margin-bottom: 8px;}
.store_visit_find{border: 1px solid #808080;padding: 10px 0; text-align: center; margin-top:12px ;margin-bottom: 32px;color: #333333;}

/* ==============================
    kbtrade_UI_22,23 
============================== */

.store_visit_re{border-top: 1px solid #EAEAEA;  font-size: 12px; color:#777777 ;margin-bottom: 40px;padding: 6px 13px 0; position: relative;}
.store_visit_re p{margin-bottom: 12px;}
.store_visit_re > div{margin-left: 16px;}
.store_visit_re span{font-size: 16px; color: #333; font-weight: 500;}
.store_visit_re span.orgbt{border: 1px solid #ff6e0e;padding: 5px 8px;color: #ff6e0e;}
.store_visit_re span.orgbt img{height: 18px;vertical-align: top;margin-right: 2px;}
.store_visit_re .spoqa{font-size: 12px; color:#777777 ;font-weight: 700;}
.store_visit_date{border: 1px solid #EAEAEA; background-color: #fff; padding: 11px 12px;margin-top: 6px; font-size: 16px; color: #98999A; font-weight: 500;position: relative; margin-bottom: 13px;}
.store_visit_date::after{content: ""; display: block; background: url(../images/ic_cal.png); width: 23px; height: 23px; background-size: contain; position: absolute;right: 21px;top: 30%;}
.store_visit_time{border: 1px solid #EAEAEA; background-color: #fff; padding: 11px 12px;margin-top: 6px; font-size: 16px; color: #98999A; font-weight: 500;position: relative;}
.store_visit_time::after{content: ""; display: block; background: url(../images/ic_con_down.png); width: 8px; height: 6px; background-size: contain; position: absolute;right: 21px;top: 50%;}

.order_wrap.store{padding: 24px 19px;border-top: 1px solid #e0e0e0;}
.order_wrap.store .store_visit_re.on{margin-bottom: 32px;}
.order_wrap.store .store_visit_re{border-top: 1px solid #EAEAEA;  font-size: 12px; color:#777777 ;margin-bottom: 8px;padding: 6px 0 0; position: relative;display: flex;}
.order_wrap.store .store_visit_re .ic_okay{height: 20px;vertical-align: text-top;margin-top: 3px;}
.order_wrap.store .ic_place{height: 18px; width: 18px;}
.order_wrap.store .store_visit_sub_tit{display: flex; align-items: center;font-size: 14px;}
.order_wrap.store .store_visit_re p.store_name{margin: 0;}
.order_wrap.store .store_visit_re p.address{margin-bottom: 0px;}
.order_wrap.store .store_visit_re > div{margin-left: 7px;}
.order_wrap.store .store_visit_re span{font-size: 16px; color: #333; font-weight: 500;line-height: 25px;}
.order_wrap.store .store_visit_re span.orgbt{border: none; border-bottom: 1px solid #808080;padding: 0px;color: #333333;font-weight: 400;}
.order_wrap.store .store_visit_re img{height: 18px;vertical-align: text-bottom;margin-right: 2px;}
.order_wrap.store .store_visit_re .spoqa{font-size: 12px; color:#777777 ;font-weight: 700;}
.store_search{position: relative;margin-bottom: 28px;}
.st_search{width: 100%;padding: 11px ;border:1px solid #DBDBDB;background-color: #fff;font-size: 16px;}
.st_search.ing{color: #333;position: relative;}
.ic_search{height: 20px;position: absolute;right: 7px; top:13px} 
.ic_delete{height: 32px;position: absolute;right: 35px;top: 8px;}
.block{padding-bottom: 9px;}
/* ==============================
    kbtrade_UI_24
============================== */
.inquiry_wrap{background-color: #FFFCF9; color: #333;padding:0;font-family: 'Noto Sans KR'; letter-spacing: -0.2px !important;
    background-size: cover;height: 100%;}
.inquiry_wrap p, .inquiry_wrap h3{margin: 0;}
.inquiry_wrap .detail_menu{background-color: #fff;border-top: 1px solid #E0E0E0;border-bottom: 1px solid #EAEAEA; height: 44px;}
.inquiry_wrap .inquiry_board{padding: 15px 16px 24px;border-bottom: 1px solid #F4ECDE ;}
.inquiry_wrap .inquiry_board h3{font-size: 18px; font-weight: 500;letter-spacing: -0.5px;}
.inquiry_wrap .inquiry_board p{color: #777777; font-size: 14px;margin-top: 8px;letter-spacing: -0.5px;}
.inquiry_wrap .inquiry_board button{background-color: #ff6e0e; color: #fff; font-size:16px ; font-weight: 500; border: none; width: 100%; padding:9px 0 10px; margin-top: 21px;}

.inquiry_wrap .receipt_info.acco{padding: 12px 16px;border-bottom: 0px;}
.inquiry_wrap .receipt_info.acco .accordion{border-top: 1px solid #DBDBDB;}
.inquiry_wrap .receipt_info.acco .accordion label{height:auto; padding: 0;justify-content: flex-start;width: 100%;padding: 11px 0;}
.inquiry_wrap .receipt_info.acco .accordion label .text_icon img{height: 24px; }
.inquiry_wrap .receipt_info.acco .accordion label .text_icon{width: 26px;font-size: 10px; letter-spacing: -0.72px;text-align: center;margin-right: 15px;}
.inquiry_wrap .receipt_info.acco .accordion label .iquiry_tit{width: calc(100% - 80px);}
.inquiry_wrap .receipt_info.acco .accordion label .iquiry_tit h3{font-size: 14px; font-weight: 500;}
.inquiry_wrap .receipt_info.acco .accordion label .iquiry_tit img{height: 15px;vertical-align: text-bottom;}
.inquiry_wrap .receipt_info.acco .accordion label .iquiry_tit.sec h3{font-size: 14px; font-weight: 500;color: #888888;}
.inquiry_wrap .receipt_info.acco .accordion label .iquiry_tit p{color: #999999; font-size: 10px;}
.inquiry_wrap .receipt_info.acco > h3{font-size: 14px;font-weight: 500;}
.inquiry_wrap .receipt_info.acco .vertical [type=radio]:checked ~ label ~ .content,.inquiry_wrap .receipt_info.acco  .vertical [type=checkbox]:checked ~ label ~ .content {
    padding: 0 0 40px;
    overflow-y: scroll;
    height: auto;
}
.inquiry_wrap .receipt_info.acco .accordion .content .iquiry_text{width: calc(100% - 40px);float: right;border: 1px solid #EEEEEE;}
.inquiry_wrap .receipt_info.acco .vertical [type=checkbox]:checked ~ label ~ .content .btn_cx_up {
    position: absolute;
    top: 23px;
    right: 0px;
    width: 18px;
    height: 9px;
    background: #fff url(../images/iquiry_up.png) no-repeat 0 0;
    background-size: auto 9px;
    z-index: 2;
}
.inquiry_wrap .receipt_info.acco .vertical .btn_cx_down {
    position: absolute;
    top: 23px;
    right: 0px;
    width: 18px;
    height: 9px;
    background: #fff url(../images/iquiry_down.png) no-repeat 0 0;
    background-size: auto 9px;
    z-index: 1;
}
.inquiry_wrap .receipt_info.acco .accordion .content .iquiry_text .iquiry_qs{background-color: #fff;padding: 4px 8px 14px;font-size: 12px;}
.inquiry_wrap .receipt_info.acco .accordion .content .iquiry_text .iquiry_as{background-color: #FFF7F1;padding: 8px 8px 14px;font-size: 12px;}
.inquiry_wrap .receipt_info.acco .accordion .content .iquiry_text .iquiry_as .answer{background-color:#FFE6D5 ; color:#BF4804 ; font-size: 10px;padding: 0px 3px 1px;margin-bottom: 4px;}


/* ==============================
    kbtrade_UI_25
============================== */
.inquiry_wrap.textarea{padding: 19px 17px;border-top: 1px solid #E0E0E0}
.inquiry_wrap.textarea h3{font-size: 16px; font-weight: 500;margin-bottom: 21px;}
.inquiry_wrap.inquiry_wrap.textarea textarea{width: 100%; border-color:#DBDBDB ;height: 220px;padding: 13px 16px; color: #9E9E9E;font-size: 14px;}
.inquiry_wrap.inquiry_wrap.textarea  > div {display: flex; justify-content: space-between; align-items: center;margin-top: 13px; color: #909090;}
.inquiry_wrap.inquiry_wrap.textarea  > div p{color: #909090;}
.iquiry_btn{position: fixed; bottom: 0;width: 100%;display: flex;}
.iquiry_btn button{width: 50%;color: #fff;border: 0px;padding: 11px 0; font-weight: 700; font-size: 16px;}
.iquiry_btn button.cancel{background-color: #757575;}
.iquiry_btn button.okay{background-color:#FF6E0E ;}

/* ==============================
    kbtrade_UI_26
============================== */
span.clorg{color: #ff6e0e !important;}
span.ls6{letter-spacing: 0.6px;}
.trade_wrap_bk .pd_pay_preview{width:100%;position: fixed; bottom: 0;background-color: #fff;border-radius: 16px 16px 0 0; overflow: hidden;padding-bottom: 48px;}
.trade_wrap_bk .pd_pay_preview .pay_tit{position: relative; background-color: #F5F5F5;}
.trade_wrap_bk .pd_pay_preview .pay_tit h3{font-size: 18px; padding: 18px 0 ; margin: 0;text-align: center;}
.trade_wrap_bk .pd_pay_preview .pay_tit img{width: 18px; height: 18px; position: absolute;top: 22px; right: 19px;}
.trade_wrap_bk .pd_pay_preview .pay_preview_list{padding: 25px 0 30px;}
.trade_wrap_bk .pd_pay_preview ul{background-color: #fff;display: flex; flex-flow: wrap row;border-bottom: 1px solid #EAEAEA;}
.trade_wrap_bk .pd_pay_preview ul li{ padding: 0px 16px; width: 50%; box-sizing: border-box;}
.trade_wrap_bk .pd_pay_preview ul li h3{margin: 0 ; font-size: 18px; font-weight: 500;}
.trade_wrap_bk .pd_pay_preview ul:last-child{border-bottom: 0px;padding-top: 10px;}
.trade_wrap_bk .pd_pay_preview ul:first-child li{width: 100%; text-align: left;}
.trade_wrap_bk .pd_pay_preview ul:first-child li > ul{display: flex;flex-direction: column;width: 100%;padding-bottom: 30px;}
.trade_wrap_bk .pd_pay_preview ul:first-child li > ul li{padding: 0;text-align: left;font-size: 14px;line-height: 24px;text-indent:-7px;}
.trade_wrap_bk .pd_pay_preview ul:first-child li > ul li::before{content: "ㆍ"; display: block; float: left;margin-right:4px;}
.trade_wrap_bk .pd_pay_preview ul li:last-child{text-align: right;}
body > div > div.pd_pay_preview > div.pay_preview_list > ul:nth-child(1) > li{text-align: left;}
.trade_wrap_bk .pd_pay_preview ul li .ck_cir{width: 20px; height: 20px; float: left; border-radius: 50%; background-color:#DBDBDB ; display: flex; justify-content: center; align-items: center; margin-right: 8px;}
.trade_wrap_bk .pd_pay_preview ul li .ck_cir img{width: 10px;}
.trade_wrap_bk .pd_pay_preview .ok_btn{position: relative;}

.trade_wrap_bk .pd_pay_preview ul.ulbd li:first-child{font-weight: 400;font-size:16px ;}
.trade_wrap_bk .pd_pay_preview ul.ulbd li:last-child{font-weight: 400;font-size:14px ;}
.trade_wrap_bk .pd_pay_preview ul.ulbd li:last-child .spoqa{font-weight: 700;font-size:20px ;}